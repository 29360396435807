import { Injectable, inject } from '@angular/core';
import { CompanyFeatureFlagsService } from '@app/_shared/service/http/companies/company-feature-flags.service';
import * as FeatureFlagsActions from '@app/main-store/feature-flags.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class FeatureFlagsEffects {
  private _actions$ = inject(Actions);
  private _companyFeatureFlagsService = inject(CompanyFeatureFlagsService);

  loadCompanyFeatureFlags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FeatureFlagsActions.loadCompanyFeatureFlags),
      switchMap(() => {
        return this._companyFeatureFlagsService.index().pipe(
          map((data) => FeatureFlagsActions.loadCompanyFeatureFlagsSuccess({ response: data })),
          catchError((err) => of(FeatureFlagsActions.loadCompanyFeatureFlagsFailed({ error: err.error }))),
        );
      }),
    ),
  );
}
