import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { ResponseObject } from '@app/_shared/interfaces';
import { BookkeepingJobs } from '@app/_shared/interfaces/http/statistics/bookkeeping-jobs';

@Injectable({
  providedIn: 'root',
})
export class BookkeepingJobsService {
  private _http = inject(HttpClient);

  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/statistics/bookkeeping';

  index(params?: HttpParams) {
    return this._http.get<ResponseObject<BookkeepingJobs>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
