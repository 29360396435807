import { Component, OnInit, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MainState } from './main-store/main-state';

@Component({
  selector: 'sb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false, // eslint-disable-line
})
export class AppComponent implements OnInit {
  store = inject<Store<MainState>>(Store);
  private _window = inject(Window);

  ngOnInit() {
    this.initiateStripe();
  }

  initiateStripe() {
    (this._window as any).stripe = window.Stripe(environment.stripePubKey);
    (this._window as any).elements = window.stripe.elements();
  }
}
