import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'sb-auth',
  template: `
    <div id="pre-loader-container">
      <div class="pre-loader-box">
        <div class="lds-dual-ring"></div>
      </div>
      <div class="pre-loader-box">
        <h2 class="pre-loader-text">Loader...</h2>
      </div>
    </div>
  `,
  standalone: false, // eslint-disable-line
})
export class AuthComponent implements OnInit {
  private _authService = inject(AuthService);
  private _window = inject(Window);

  ngOnInit() {
    if ((this._window as any)?.dataLayer) {
      (this._window as any)?.dataLayer.push({ event: 'login' });
    }

    this._authService.handleRedirectCallback();
  }
}
