import { Component, HostBinding, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlag } from '@app/_shared/enum/feature-flag.enum';
import { DataObject, Stores } from '@app/_shared/interfaces';
import { getDefaultStore } from '@app/main-store/bootstrap.selector';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { Store } from '@ngrx/store';
import { EMPTY, map, Observable, Subject, takeUntil } from 'rxjs';

export interface MenuItem {
  iconPrefix?: IconPrefix;
  icon: IconName;
  title: string;
  route: string;
  dividerTop?: boolean;
  active?: boolean;
  featureFlag?: FeatureFlag;
}

@Component({
  selector: 'sb-sidenav',
  templateUrl: './sidenav.component.html',
  standalone: false, // eslint-disable-line
})
export class SidenavComponent implements OnInit, OnDestroy {
  private rxStore = inject<Store<any>>(Store);

  @Input() menuItems: MenuItem[] = [];

  @Input() animate = true;

  @Input() hide = false;

  private componentDestroyed$: Subject<void> = new Subject();

  public activeStoreId: string | number = null;

  public defaultStore$: Observable<DataObject<Stores> | null> = EMPTY;

  @HostBinding('class')
  get hostClasses() {
    let classes = `tw-sticky tw-h-[calc(100dvh-theme("spacing.16"))] tw-h-full tw-bg-[white] tw-border-r tw-border-gray-100 tw-shadow-sm tw-shadow-gray-100 tw-z-20 tw-top-0 tw-group/menu tw-p-3 tw-overflow-hidden ${this.hide ? 'tw-w-0 !tw-p-0' : this.animate ? 'tw-w-16 hover:tw-w-52 tw-transition-all tw-duration-200 tw-ease-in-out' : 'tw-w-52'}`;
    return classes;
  }

  ngOnInit(): void {
    this.defaultStore$ = this.rxStore.select(getDefaultStore).pipe(
      takeUntil(this.componentDestroyed$),
      map((store) => {
        this.activeStoreId = store?.id;
        return store;
      }),
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
