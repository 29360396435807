import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { CustomPipeModule } from '@app/_shared/pipes/custom-pipe.module';
import { SnackBarModule } from '@app/_shared/snack-bars/snack-bar.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { BootstrapEffects } from '@app/main-store/bootstrap.effects';
import { SubscriptionEffects } from '@app/main-store/subscriptions/subscription.effects';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UrlInterceptor } from './_interceptors/url.interceptor';
import { SidenavModule } from './_shared/components/sidenav/sidenav.module';
import { ToolbarModule } from './_shared/components/toolbar/toolbar.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { CustomErrorHandler } from './global-error-handler';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutEffects } from './main-store/layout.effects';
import { reducers } from './main-store/root-reducer';
import { MaintenanceComponent } from './maintenance/maintenance.component';

// Material
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DisplayTextPipe } from './_shared/components/select-search/display-text.pipe';
import { FeatureFlagsEffects } from './main-store/feature-flags.effects';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { UserflowService } from './_shared/service/userflow.service';
import { primengConfig } from './primeng.config';

registerLocaleData(localeDa, 'da');
declare const window;
const skipRedirectCallbacksFor = ['/integrations/shopify', '/integrations/stripe', '/oauth/install'];

@NgModule({
  declarations: [AppComponent, AuthComponent, MainLayoutComponent, MaintenanceComponent],
  bootstrap: [AppComponent],
  imports: [
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        audience: environment.auth0Audience,
        redirect_uri: `${window.location.protocol}//${window.location.host}/auth`,
      },
      httpInterceptor: {
        allowedList: [environment.apiUrl + '/*', environment.apiLaravelUrl + '/*'],
      },
      skipRedirectCallback: skipRedirectCallbacksFor.includes((window as any).location.pathname),
      cacheLocation: 'localstorage',
    }),
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([LayoutEffects, BootstrapEffects, FeatureFlagsEffects, SubscriptionEffects]),
    DashboardModule,
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    AppRoutingModule,
    MatSidenavModule,
    PortalModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatTooltipModule,
    MatTabsModule,
    LayoutModule,
    MatToolbarModule,
    CustomPipeModule,
    FontAwesomeImportModule,
    SnackBarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    ToolbarModule,
    SidenavModule,
    StoreRouterConnectingModule.forRoot(),
    MatMenuModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatButtonModule,
    DragDropModule,
  ],
  providers: [
    DisplayTextPipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: Window, useValue: window },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG(primengConfig),
    UserflowService,
  ],
})
export class AppModule {}
