import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataObject } from '@app/_shared/interfaces';
import { OrderSearchResult } from '@app/_shared/interfaces/http/order/order';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { Subject, catchError, filter, finalize, fromEvent, map, of, take, takeUntil } from 'rxjs';

@Component({
  selector: 'sb-search-bar',
  templateUrl: './search-bar.component.html',
  standalone: false, // eslint-disable-line
})
export class SearchBarComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private _dashboardService = inject(DashboardService);

  @HostBinding('class')
  get hostClasses() {
    let classes = 'tw-flex tw-w-96 tw-relative';
    return classes;
  }

  private componentDestroyed$: Subject<void> = new Subject();

  public activeStoreId: string | number = null;

  public searchControl = new UntypedFormControl();
  public searchResults: DataObject<OrderSearchResult>[] = [];
  public searchOpen = false;
  public searching = false;
  public searchFailed = false;
  public searchNoResults = false;
  public showEnterIndicator = false;

  search() {
    if (this.searchControl.getRawValue() === null || this.searchControl.getRawValue().trim() === '') {
      return;
    }
    this.searching = true;
    return this._dashboardService
      .searchOrderByWebNo('search', this.searchControl.getRawValue())
      .pipe(
        take(1),
        map(({ data }) => {
          this.searchFailed = false;
          this.searchNoResults = data.length === 0;
          this.searchResults = data;
        }),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        }),
        finalize(() => {
          this.searching = false;
          this.showEnterIndicator = false;
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    fromEvent(document, 'keyup')
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((event: KeyboardEvent) => event && event.key && !['enter', 'escape'].includes(event.key.toLowerCase())),
        map((event) => ({
          key: event.key.toLowerCase(),
          term: this.searchControl.getRawValue(),
        })),
        filter(({ term }) => typeof term === 'string'),
        map(({ term }) => {
          this.searchResults = [];
          this.searchOpen = false;
          this.showEnterIndicator = term.length > 0;
        }),
      )
      .subscribe();
  }

  resetSearch() {
    this.searchResults = [];
    this.searchOpen = false;
    this.searchFailed = false;
    this.searchNoResults = false;
    this.showEnterIndicator = this.searchControl.value?.length > 0;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  onOptionSelected(orderId: string) {
    this.searchResults = [];
    this.router.navigate(['/', 'ordrer', orderId]);
    (document.activeElement as HTMLElement).blur();
  }

  displayWith(order) {
    return order ? order.web_no : '';
  }
}
