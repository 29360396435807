import { Injectable, inject } from '@angular/core';
import { BookkeepingJobsService } from '@app/_shared/service/http/statistics/bookkeeping-jobs.service';
import { CompanyService } from '@app/_shared/service/http/statistics/company.service';
import { OrdersService } from '@app/_shared/service/http/statistics/orders.service';
import { selectLastUpdatedAt } from '@app/dashboard/store/dashboard.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as DashboardActions from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  private actions$ = inject(Actions);
  private rxStore = inject(Store);
  private bookkeepingJobsService = inject(BookkeepingJobsService);
  private ordersCountService = inject(OrdersService);
  private companyCounts = inject(CompanyService);

  loadDashboards$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadDashboardStatistics),
      switchMap(() =>
        forkJoin({
          bookkeepingJobs: this.bookkeepingJobsService.index(),
          ordersCount: this.ordersCountService.index(),
          company: this.companyCounts.index(),
        }).pipe(
          map((data) =>
            DashboardActions.loadDashboardStatisticsSuccess({
              data: {
                bookkeepingJobsCount: data.bookkeepingJobs.data.attributes.bookkeepings_created_today,
                ordersCount: data.ordersCount.data.attributes.orders_created_today,
                apiIntegrationsCount: data.company.data.attributes.api_integrations_count,
              },
            }),
          ),
        ),
      ),
    );
  });

  loadInitialDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.loadInitialDashboardStatistics),
      concatLatestFrom(() => this.rxStore.select(selectLastUpdatedAt)),
      switchMap(([, lastUpdatedAt]) => {
        if (lastUpdatedAt !== undefined) {
          return EMPTY;
        }
        return of(DashboardActions.loadDashboardStatistics());
      }),
    );
  });
}
