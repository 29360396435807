import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlag as FeatureFlagEnum } from '@app/_shared/enum/feature-flag.enum';
import { DataObject } from '@app/_shared/interfaces';
import { FeatureFlag } from '@app/_shared/interfaces/http/companies/feature-flag';
import * as FeatureFlagsActions from '@app/main-store/feature-flags.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, Observable } from 'rxjs';

const requiredFefatureFlag = FeatureFlagEnum.BankIntegrations;

@Injectable({
  providedIn: 'root',
})
export class GoCardlessTransactionsGuard {
  private _actions$ = inject(Actions);
  private _store = inject(Store);
  private _router = inject(Router);

  canActivate(
    _activatedRouteSnapshot: ActivatedRouteSnapshot,
    _routerStateSnapshot: RouterStateSnapshot,
  ): Observable<boolean> {
    this._store.dispatch(FeatureFlagsActions.loadCompanyFeatureFlags());

    return combineLatest([this.ofLoadCompanyFeatureFlags$()]).pipe(
      first(),
      map(([featureFlagAction]) => {
        if (
          featureFlagAction.type === FeatureFlagsActions.loadCompanyFeatureFlagsSuccess.type &&
          this.companyHasFeatureFlag(featureFlagAction.response.data)
        ) {
          return true;
        }
        this._router.navigate(['/']);
        return false;
      }),
    );
  }

  private ofLoadCompanyFeatureFlags$ = () =>
    this._actions$.pipe(
      ofType(FeatureFlagsActions.loadCompanyFeatureFlagsSuccess, FeatureFlagsActions.loadCompanyFeatureFlagsFailed),
      first(),
    );

  private companyHasFeatureFlag(flags: DataObject<FeatureFlag>[]): boolean {
    return flags.some((flag) => flag.attributes.key === requiredFefatureFlag);
  }
}
