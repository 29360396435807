import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { LayoutActionTypes, OpenSnackbar } from './layout.actions';

@Injectable()
export class LayoutEffects {
  private snackBar = inject(MatSnackBar);
  private _actions$ = inject(Actions);

  openSnackbar = createEffect(
    () =>
      this._actions$.pipe(
        ofType<OpenSnackbar>(LayoutActionTypes.OpenSnackbar),
        tap((action) =>
          this.snackBar.open(action.title, action.action, { ...action.opts, panelClass: 'global-error-snackbar' }),
        ),
      ),
    { dispatch: false },
  );
}
