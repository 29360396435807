import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { colorPalettes } from 'src/assets/color-palettes';

const CustomPrimeNGPreset = definePreset(Aura, {
  components: {
    divider: {
      horizontal: {
        margin: 0,
        padding: 0,
      },
      vertical: {
        margin: 0,
        padding: 0,
      },
    },
  },
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}',
    },
    colors: colorPalettes,
  },
});

export const primengConfig = {
  translation: {
    accept: 'Ja',
    addRule: 'Tilføj regel',
    am: 'am',
    apply: 'Anvend',
    cancel: 'Annuller',
    choose: 'Vælg',
    chooseDate: 'Vælg dato',
    chooseMonth: 'Vælg måned',
    chooseYear: 'Vælg år',
    clear: 'Ryd',
    contains: 'Indeholder',
    dateAfter: 'Dato er efter',
    dateBefore: 'Dato er før',
    dateFormat: 'dd/mm-yy',
    dateIs: 'Dato er',
    dateIsNot: 'Dato er ikke',
    dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    dayNamesMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
    dayNamesShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
    emptyFilterMessage: 'Ingen resultater fundet',
    emptyMessage: 'Ingen tilgængelige muligheder',
    emptySearchMessage: 'Ingen resultater fundet',
    emptySelectionMessage: 'Ingen valgte elementer',
    endsWith: 'Slutter med',
    equals: 'Lig med',
    fileChosenMessage: '{0} filer',
    fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    firstDayOfWeek: 0,
    gt: 'Større end',
    gte: 'Større end eller lig med',
    lt: 'Mindre end',
    lte: 'Mindre end eller lig med',
    matchAll: 'Match alle',
    matchAny: 'Match nogen',
    medium: 'Medium',
    monthNames: [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december',
    ],
    monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    nextDecade: 'Næste årti',
    nextHour: 'Næste time',
    nextMinute: 'Næste minut',
    nextMonth: 'Næste måned',
    nextSecond: 'Næste sekund',
    nextYear: 'Næste år',
    noFileChosenMessage: 'Ingen fil valgt',
    noFilter: 'Ingen filtrering',
    notContains: 'Indeholder ikke',
    notEquals: 'Ikke lig med',
    passwordPrompt: 'Indtast adgangskode',
    pending: 'Afventer',
    pm: 'pm',
    prevDecade: 'Forrige årti',
    prevHour: 'Forrige time',
    prevMinute: 'Forrige minut',
    prevMonth: 'Forrige måned',
    prevSecond: 'Forrige sekund',
    prevYear: 'Forrige år',
    reject: 'Nej',
    removeRule: 'Fjern regel',
    searchMessage: '{0} resultater er tilgængelige',
    selectionMessage: '{0} elementer valgt',
    startsWith: 'Starter med',
    strong: 'Stærk',
    today: 'I dag',
    upload: 'Upload',
    weak: 'Svag',
    weekHeader: 'Uge',
    aria: {
      cancelEdit: 'Annullér ændringer',
      close: 'Luk',
      collapseRow: 'Række foldet sammen',
      editRow: 'Redigér række',
      expandRow: 'Række udvidet',
      falseLabel: 'Falsk',
      filterConstraint: 'Filterbegrænsning',
      filterOperator: 'Filteroperator',
      firstPageLabel: 'Første side',
      gridView: 'Gittervisning',
      hideFilterMenu: 'Skjul filtermenu',
      jumpToPageDropdownLabel: 'Skift til sidenummer',
      jumpToPageInputLabel: 'Skift til sidenummer',
      lastPageLabel: 'Sidste side',
      listLabel: 'Valgliste',
      listView: 'Listevisning',
      moveAllToSource: 'Flyt alle til kilde',
      moveAllToTarget: 'Flyt alle til mål',
      moveBottom: 'Flyt til bunden',
      moveDown: 'Flyt ned',
      moveTop: 'Flyt til top',
      moveToSource: 'Flyt til kilde',
      moveToTarget: 'Flyt til mål',
      moveUp: 'Flyt op',
      navigation: 'Navigation',
      next: 'Næste',
      nextPageLabel: 'Næste side',
      nullLabel: 'Ikke valgt',
      pageLabel: 'Side {page}',
      previous: 'Forrige',
      prevPageLabel: 'Forrige side',
      removeLabel: 'Fjerne',
      rotateLeft: 'Roter mod venstre',
      rotateRight: 'Roter mod højre',
      rowsPerPageLabel: 'Rækker pr. side',
      saveEdit: 'Gem ændringer',
      scrollTop: 'Rul til toppen',
      selectAll: 'Alle elementer valgt',
      selectRow: 'Række valgt',
      showFilterMenu: 'Vis filtermenu',
      slide: 'Slide',
      slideNumber: '{slideNumber}',
      star: '1 stjerne',
      stars: '{star} stjerner',
      trueLabel: 'Sandt',
      unselectAll: 'Alle elementer fjernet',
      unselectRow: 'Række fjernet',
      zoomImage: 'Zoom ind på billede',
      zoomIn: 'Zoom ind',
      zoomOut: 'Zoom ud',
    },
  },
  theme: {
    preset: CustomPrimeNGPreset,
    options: {
      darkModeSelector: false,
    },
  },
};
