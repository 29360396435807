import { ApiCallState, CompanyBootstrap, RequestState } from '@app/_shared/interfaces';
import { DataObject } from '@app/_shared/interfaces/http/data';
import { mergeAttributesById, replaceById } from '@app/_shared/support';
import * as BootstrapActions from '@app/main-store/bootstrap.actions';
import { getSubscriptionOns } from '@app/main-store/subscriptions/subscripton.reducer';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
  company: DataObject<CompanyBootstrap> | null;
  defaultStoreId: number | null;
  requestState: ApiCallState;
}

export const initialState: State = {
  company: {
    attributes: {
      address: '',
      city: '',
      contact_email: '',
      contact_person: '',
      contact_phone: '',
      country: null,
      created_at: '',
      cvr: undefined,
      is_deleted: false,
      is_terms_approved: false,
      name: '',
      stores: [],
      stripe_id: '',
      updated_at: '',
      version: 0,
      zip: '',
      failed_bookkeeping_outbox: false,
    },
    id: 0,
    type: '',
  },
  defaultStoreId: null,
  requestState: RequestState.INIT,
};

const featureReducer = createReducer(
  initialState,
  on(BootstrapActions.loadCompanyBootstrapSuccess, (state, action) => ({
    ...state,
    company: action.data,
  })),
  on(BootstrapActions.loadCompanyBootstrapFailed, () => initialState),
  on(BootstrapActions.updateCompany, (state) => ({ ...state, requestState: RequestState.SAVING })),
  on(BootstrapActions.updateCompanySuccess, (state, action) => ({
    ...state,
    company: {
      ...state.company,
      attributes: {
        ...state.company.attributes,
        ...action.data.attributes,
      },
    },
    requestState: RequestState.SAVED,
  })),
  on(BootstrapActions.updateCompanyFailed, (state, action) => ({ ...state, requestState: action })),
  on(BootstrapActions.setDefaultStoreById, (state, action) => ({
    ...state,
    defaultStoreId: action.storeId === 0 ? null : action.storeId,
  })),
  on(BootstrapActions.updateStore, (state) => ({ ...state, requestState: RequestState.SAVING })),
  on(BootstrapActions.updateStoreSuccess, (state, action) => ({
    ...state,
    company: {
      ...state.company,
      attributes: {
        ...state.company.attributes,
        stores: mergeAttributesById(state.company.attributes.stores, action.data.id, action.data),
      },
    },
    requestState: RequestState.SAVED,
  })),
  on(BootstrapActions.updateStoreFailed, (state, action) => ({ ...state, requestState: action })),
  on(BootstrapActions.updateStoreSettings, (state) => ({ ...state, requestState: RequestState.SAVING })),
  on(BootstrapActions.updateStoreSettingsSuccess, (state, action) => {
    let updatedStore = state.company.attributes.stores.find((store) => store.id === action.data.attributes.store_id);
    updatedStore = { ...updatedStore, attributes: { ...updatedStore.attributes, store_setting: action.data } };

    return {
      ...state,
      company: {
        ...state.company,
        attributes: {
          ...state.company.attributes,
          stores: replaceById(state.company.attributes.stores, updatedStore.id, updatedStore),
        },
      },
      requestState: RequestState.SAVED,
    };
  }),
  on(BootstrapActions.updateStoreSettingsFailed, (state, action) => ({ ...state, requestState: action })),
  ...getSubscriptionOns(),
);

export function reducer(state: State | undefined, action: Action) {
  return featureReducer(state, action);
}
