import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList } from '@app/_shared/interfaces';
import { OrderSearchResult } from '@app/_shared/interfaces/http/order/order';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private _http = inject(HttpClient);

  private headers = new HttpLaravelHeaders();
  private endpoint = '/api/v1/user/orders/search';
  public isLoading = false;

  searchOrderByWebNo(queryProperty: string, queryValue: string) {
    const params = new HttpParams().append(`filter[${queryProperty}]`, queryValue);

    return this._http.get<DataList<OrderSearchResult>>(this.endpoint, { headers: this.headers, params });
  }
}
