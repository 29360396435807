import { Portal } from '@angular/cdk/portal';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from '@app/_shared/components/sidenav/sidenav.component';
import { FeatureFlag as FeatureFlagEnum } from '@app/_shared/enum/feature-flag.enum';
import { GlobalSlideInService } from '@app/_shared/global-slide-in/services/global-slide-in.service';
import { DataObject } from '@app/_shared/interfaces';
import { FeatureFlag } from '@app/_shared/interfaces/http/companies/feature-flag';
import { companyFeatureFlags } from '@app/main-store/feature-flags.selectors';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const accountingSideNavItems: MenuItem[] = [
  {
    icon: 'basket-shopping',
    title: 'Salg',
    route: '/ordrer',
  },
  {
    icon: 'money-bill-transfer',
    title: 'Betalinger',
    route: '/betalinger/gateway',
  },
  {
    icon: 'money-bill-wave',
    title: 'Tilbagebetalinger',
    route: '/tilbage-betalinger/gateway',
  },
  {
    icon: 'money-check-pen',
    title: 'Korrektioner',
    route: '/korrektioner',
  },
  {
    icon: 'file-export',
    title: 'Samleposteringer',
    route: '/bundles',
  },
  {
    icon: 'scale-unbalanced',
    title: 'Ordrebalance',
    route: '/balances/orders-postings',
    dividerTop: true,
  },
  {
    icon: 'calculator-simple',
    title: 'Saldobalance',
    route: '/saldobalance',
  },
  {
    icon: 'piggy-bank',
    title: 'Udbetalinger',
    route: '/bank',
  },
  {
    iconPrefix: 'fal',
    icon: 'building-columns',
    title: 'Bank transaktioner',
    route: '/transaktioner',
    featureFlag: FeatureFlagEnum.BankIntegrations,
  },
  // Hidden until new toolbox menu is ready
  // {
  //   icon: 'piggy-bank',
  //   title: 'Ekstern',
  //   route: '/accounting-entries',
  // Add this when reportJobResults is implemented
  // {
  //   icon: 'file-chart-column',
  //   title: 'Rapporter',
  //   route: '/rapporter',
  //   dividerTop: true,
  // },
];

export const orderTabGroupItems = [
  { label: 'Ordrer', route: '/ordrer' },
  { label: 'Refunderinger', route: '/refunderinger' },
  { label: 'Fulfillments', route: '/fulfillments' },
  { label: 'Returns', route: '/returns' },
];

export const paymentsTabGroupItems = [
  { label: 'Betalingsgateway', route: '/betalinger/gateway' },
  { label: 'Salgskanal', route: '/betalinger/salgskanal' },
  { label: 'Gavekort', route: '/betalinger/gavekort' },
];

export const repaymentsTabGroupItems = [
  { label: 'Betalingsgateway', route: '/tilbage-betalinger/gateway' },
  { label: 'Salgskanal', route: '/tilbage-betalinger/salgskanal' },
  { label: 'Gavekort', route: '/tilbage-betalinger/gavekort' },
];

export const balanceTabGroupItems = [{ label: 'Posteringsbalance', route: '/balances/orders-postings' }];

@Component({
  selector: 'sb-main-layout',
  templateUrl: './main-layout.component.html',
  standalone: false, // eslint-disable-line
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private slideInService = inject(GlobalSlideInService);
  private rxStore = inject(Store);

  @HostBinding('class')
  get hostClasses() {
    return 'tw-grid tw-grid-rows-[auto_1fr]';
  }

  @ViewChild('sideNav') slideIn: MatDrawer;

  public slideInPortal: Portal<any>;
  public slideInWidth = '30rem';
  public slideInBackdrop = false;
  public slideInMode: MatDrawerMode = 'over';
  public slideInAutoFocus = false;

  public tabGroupItems = [];
  public sideNavItems: MenuItem[] = [];

  get filteredSideNavItems() {
    return this.sideNavItems.filter((item) =>
      item.featureFlag ? this.featureFlags.some((flag) => flag.attributes.key === item.featureFlag) : true,
    );
  }

  private componentDestroyed$: Subject<void> = new Subject();

  public activeRouteGroup: 'home' | 'accounting' | 'settings' | 'account' | null = 'accounting';
  private _activeRouteGroups = new Map([
    ['settings', ['integrations']],
    [
      'accounting',
      [
        'ordrer',
        'betalinger',
        'refunderinger',
        'tilbage-betalinger',
        'bank',
        'korrektioner',
        'balances',
        'bundles',
        'fulfillments',
        'returns',
        'saldobalance',
        'transaktioner',
        'rapporter',
        'accounting-entries',
      ],
    ],
    ['account', ['konto']],
    ['home', ['']],
  ]);

  public activeAccountingGroup:
    | 'order-view'
    | 'orders'
    | 'payments'
    | 'repayments'
    | 'corrections'
    | 'bundles'
    | 'balance'
    | 'payouts'
    | 'returns'
    | 'account-balance'
    | 'gc-transactions'
    | 'reports'
    | 'accounting-entries'
    | null = 'orders';
  private _activeAccountingGroups = new Map([
    ['order-view', ['/ordrer/']],
    ['orders', ['/ordrer', '/refunderinger', '/fulfillments', '/returns']],
    ['payments', ['/betalinger/salgskanal', '/betalinger/gateway', '/betalinger/gavekort']],
    ['repayments', ['/tilbage-betalinger/salgskanal', '/tilbage-betalinger/gateway', '/tilbage-betalinger/gavekort']],
    ['corrections', ['/korrektioner']],
    ['bundles', ['/bundles']],
    ['balance', ['/balances/orders-postings']],
    ['payouts', ['/bank']],
    ['account-balance', ['/saldobalance']],
    ['gc-transactions', ['/transaktioner']],
    ['reports', ['/rapporter']],
    ['accounting-entries', ['/accounting-entries']],
  ]);

  private tabGroupItemsMap = {
    orders: orderTabGroupItems,
    payments: paymentsTabGroupItems,
    repayments: repaymentsTabGroupItems,
  };

  public featureFlags: DataObject<FeatureFlag>[] = [];

  public activeRoute = '';

  ngOnInit() {
    this.slideInService.currentPortal$
      .pipe(filter(({ portal }) => portal !== null))
      .subscribe(({ portal, width, backdrop, mode, autoFocus }) => {
        this.slideInPortal = portal;
        this.slideInWidth = width ?? '30rem';
        this.slideInBackdrop = backdrop;
        this.slideInMode = mode;
        this.slideInAutoFocus = autoFocus;
        this.slideIn.open();
      });

    this.slideInService.close$.subscribe(() => {
      this.slideIn.close();
    });

    this.activeRoute = this.removeQueryParameters(this.router.url);

    this.determineActiveRouteGroup(this.router.url);

    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = this.removeQueryParameters(event.urlAfterRedirects);
        this.determineActiveRouteGroup(event.urlAfterRedirects);
      });

    this.rxStore
      .select(companyFeatureFlags)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((flags) => (this.featureFlags = flags));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onCloseSlideIn() {
    this.slideInService.close();
  }

  public setActiveRoute(route: string) {
    this.activeRoute = route;
  }

  public isActiveRoute(route: string) {
    return this.activeRoute === route;
  }

  private determineActiveRouteGroup(url: string) {
    this.activeRouteGroup = Array.from(this._activeRouteGroups.keys()).filter((_key) =>
      this._activeRouteGroups.get(_key).some((_path) => url.includes(_path)),
    )[0] as 'home' | 'accounting' | 'settings' | 'account';

    switch (this.activeRouteGroup) {
      case 'accounting':
        this.determineActiveAccountingGroup(url);
        this.sideNavItems = accountingSideNavItems;
        break;
      default:
        this.sideNavItems = [];
        break;
    }
  }

  private determineActiveAccountingGroup(url: string) {
    this.activeAccountingGroup = Array.from(this._activeAccountingGroups.keys()).filter((_key) =>
      this._activeAccountingGroups.get(_key).some((_path) => url.includes(_path)),
    )[0] as
      | 'order-view'
      | 'orders'
      | 'payments'
      | 'repayments'
      | 'corrections'
      | 'bundles'
      | 'balance'
      | 'payouts'
      | 'returns'
      | 'account-balance'
      | 'gc-transactions'
      | 'accounting-entries';

    this.tabGroupItems = this.tabGroupItemsMap[this.activeAccountingGroup] || [];

    this.updateActiveNavItem();
  }

  private updateActiveNavItem() {
    accountingSideNavItems.forEach((item) => {
      item.active = this._activeAccountingGroups
        .get(this.activeAccountingGroup)
        .some((route) => item.route.includes(route));
    });
  }

  private removeQueryParameters(url: string): string {
    return url.split('?')[0];
  }
}
