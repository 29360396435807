import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'sb-signup-redirect',
  template: `
    <div id="pre-loader-container">
      <div class="pre-loader-box">
        <div class="lds-dual-ring"></div>
      </div>
      <div class="pre-loader-box">
        <h2 class="pre-loader-text">Viderestilles...</h2>
      </div>
    </div>
  `,
  standalone: false, // eslint-disable-line
})
export class SignupRedirectComponent implements OnInit {
  private _authService = inject(AuthService);

  ngOnInit() {
    this._authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: { target: '/stores/sales-platform' },
    });
  }
}
