import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyBootstrap, DataObject } from '@app/_shared/interfaces';
import { Stores } from '@app/_shared/interfaces/http/stores';
import { SupportLinksService } from '@app/_shared/service';
import * as BootstrapActions from '@app/main-store/bootstrap.actions';
import {
  getActiveStores,
  getCompany,
  getDefaultStore,
  hasOnlyShopifyBillingService,
} from '@app/main-store/bootstrap.selector';
import { AuthService } from '@auth0/auth0-angular';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';

@Component({
  selector: 'sb-toolbar',
  templateUrl: './toolbar.component.html',
  standalone: false, // eslint-disable-line
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private _authService = inject(AuthService);
  private supportLinkService = inject(SupportLinksService);
  private router = inject(Router);
  private rxStore = inject<Store<any>>(Store);

  readonly activeRouteGroup = input<'home' | 'accounting' | 'settings' | 'account' | null>('accounting');

  private componentDestroyed$: Subject<void> = new Subject();
  public stores$: Observable<DataObject<Stores>[]> = this.rxStore.pipe(
    takeUntil(this.componentDestroyed$),
    select(getActiveStores),
  );
  public defaultStore$: Observable<DataObject<Stores> | null> = this.rxStore.pipe(
    takeUntil(this.componentDestroyed$),
    select(getDefaultStore),
  );
  public companyInfo$: Observable<DataObject<CompanyBootstrap> | null>;
  public defaultStoreId$: Observable<number | null> = this.defaultStore$.pipe(
    map((store) => (store?.id ? +store.id : null)),
  );

  public hasOnlyShopifyBillingService$: Observable<boolean> = this.rxStore.select(hasOnlyShopifyBillingService);

  ngOnInit(): void {
    this.companyInfo$ = this.rxStore.pipe(takeUntil(this.componentDestroyed$), select(getCompany));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getHelpCenterSupportLink(): string {
    return this.supportLinkService.getList().mainLayout.helpCenter;
  }

  logout() {
    this._authService.logout({
      logoutParams: {
        returnTo: `https://storebuddy.dk`,
      },
    });
  }

  onStoreSelectAction(storeId: number | null, route: string = '/') {
    this.rxStore.dispatch(BootstrapActions.setDefaultStoreById({ storeId: storeId ?? null }));
    this.router.navigate([route]);
  }
}
